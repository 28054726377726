import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Attribute from './Attribute'
import Orders from './Orders'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    background: 'transparent',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paperOrderDescription: {
    background: 'transparent',
    padding: theme.spacing(1),
    textAlign: 'left',
    fontSize: '10px'
  },
  paperOrderValues: {
    background: 'transparent',
    textAlign: 'right',
    fontSize: '10px'
  },
}));

export default function Default({invoice, invoiceHandler}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
          <Typography variant="h6">
            Overview
          </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper 
            className={classes.paper}
            style={{textAlign: 'left'}}
          >
          <Typography variant="body2">
            {(invoice.buyer_company_brand || invoice.buyer_first_name) ? (
              <>
              {invoice.buyer_company_brand ? invoice.buyer_company_brand : (invoice.buyer_first_name.slice(0,1) + '. ' + invoice.buyer_last_name)} # {invoice.display}
              </>
            ):(
              null
            )}
          </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper 
            className={classes.paper}
            style={{textAlign: 'right'}}
          >
          <Typography variant="body2">
            {invoice.seller_company_brand}
          </Typography>
          </Paper>
        </Grid>


        <Grid item xs={12} sm={6}>
          <Paper 
            className={classes.paper}
            style={{textAlign: 'left'}}            
          >
          <Typography variant="body2">
            TO
          </Typography>
          <hr />
          <Attribute
            label='Company Legal Name'
            value={invoice.buyer_company_legal}
          />
          <Attribute
            label='Full Name'
            value={invoice.buyer_first_name}
            value1={invoice.buyer_last_name}
          />
          <br />
          <Attribute
            label='Address'
            value={invoice.buyer_address}
          />
          <Attribute
            label='Postal Code'
            value={invoice.buyer_zip}
          />
          <Attribute
            label='City'
            value={invoice.buyer_city}
          />
          <Attribute
            label='Country'
            value={invoice.buyer_country}
          />
          <br />
          <Attribute
            label='Phone'
            value={invoice.buyer_phone}
          />
          <Attribute
            label='Email'
            value={invoice.buyer_email}
          />
          <br />
          <Attribute
            label='Company Registration #'
            value={invoice.buyer_registration}
          />
          <Attribute
            label='VAT #'
            value={invoice.buyer_vat}
          />
          <br />
          <Attribute
            label='INVOICE #'
            value={invoice.display}
          />
          <Attribute
            label='ISSUED'
            value={invoice.issue_date.toJSON().slice(0,10)}
          />
          <Attribute
            label='DUE DATE'
            value={invoice.due_date.toJSON().slice(0,10)}
          />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper 
            className={classes.paper}
            style={{textAlign: 'left'}}
          >
          <Typography variant="body2">
            FROM
          </Typography>
          <hr />
          <Attribute
            label='Company Legal Name'
            value={invoice.seller_company_legal}
          />
          <Attribute
            label='Full Name'
            value={invoice.seller_first_name}
            value1={invoice.seller_first_name}
          />
          <br />
          <Attribute
            label='Address'
            value={invoice.seller_address}
          />
          <Attribute
            label='Postal Code'
            value={invoice.seller_zip}
          />
          <Attribute
            label='City'
            value={invoice.seller_city}
          />
          <Attribute
            label='Country'
            value={invoice.seller_country}
          />
          <br />
          <Attribute
            label='Phone'
            value={invoice.seller_phone}
          />
          <Attribute
            label='Email'
            value={invoice.seller_email}
          />
          <br />
          <Attribute
            label='Company Registration #'
            value={invoice.seller_registration}
          />
          <Attribute
            label='VAT #'
            value={invoice.seller_vat}
          />

          </Paper>
        </Grid>

        <Orders invoice={invoice} invoiceHandler={invoiceHandler}/>

        <Grid item xs={6}>
          <Paper
            className={classes.paper}
            style={{textAlign: 'left'}}
          >
          <Attribute
            label='BIC/SWIFT'
            value={invoice.bic_swift}
          />
          <Attribute
            label='IBAN'
            value={invoice.iban}
          />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper
            className={classes.paper}
            style={{textAlign: 'right'}}  
          >
            <Attribute
            label='PRICE'
            value={invoice.price}
          />
          <Attribute
            label='VAT'
            value={invoice.vat}
          />
          <Attribute
            label='TOTAL'
            value={invoice.total}
          />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper
            className={classes.paper}
            style={{textAlign: 'left'}}  
          >
          <Attribute
            label='NOTES'
            value={invoice.notes}
          />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={4}>
          <Paper
            className={classes.paper}
            style={{textAlign: 'right'}}  
          >
          <Attribute
            label='WEB'
            value={invoice.seller_web}
          />
        </Paper>
        </Grid>
        <Grid item xs={6} sm={4}>
        <Paper
            className={classes.paper}
            style={{textAlign: 'right'}}  
          >
          <Attribute
            label='INSTAGRAM'
            value={invoice.seller_ig}
          />            
          </Paper>
        </Grid>
        <Grid item xs={6} sm={4}>
        <Paper
            className={classes.paper}
            style={{textAlign: 'right'}}  
          >
          <Attribute
            label='LOCATION'
            value={invoice.seller_country}
          />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
