import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PaperMui from '@material-ui/core/Paper';

import AssignmentIcon from '@material-ui/icons/Assignment';
import BusinessIcon from '@material-ui/icons/Business'; 
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import EuroIcon from '@material-ui/icons/Euro';
import BallotIcon from '@material-ui/icons/Ballot'; 
import DoneAllIcon from '@material-ui/icons/DoneAll'; 

import Paper from '../../../components/Paper'

import Invoice from '../components/Create/Invoice';
import Seller from '../components/Create/Seller';
import Buyer from '../components/Create/Buyer';
import Payment from '../components/Create/Payment';
import Orders from '../components/Create/Orders';
import Overview from '../components/Create/Overview';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: '#fff'
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 0,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles(() => ({
  root: {
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    border: '2px dotted #fff',
    backgroundColor: '#111'
  },
  completed: {
    border: '1px solid #fff',
    backgroundColor: '#546e7a'
  },
}));

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <AssignmentIcon />,
    2: <BusinessIcon />,
    3: <HomeWorkIcon />,
    4: <EuroIcon />,
    5: <BallotIcon />,
    6: <DoneAllIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
    },
    background: 'transparent'
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    padding: theme.spacing(0, 0, 1),
    background: 'transparent',
  },
  stepLabel: {
    '&:hover': {
      cursor: 'pointer'
    },
  },
  stepLabelText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  }
}));

function getSteps() {
  return ['Invoice', 'Seller', 'Buyer', 'Payment', 'Orders', 'Overview'];
}

function getStepContent(step, invoice, invoiceHandler, createInvoice) {
  switch (step) {
    case 0:
      return <Invoice invoice={invoice} invoiceHandler={invoiceHandler}/>; 
    case 1:
      return <Seller invoice={invoice} invoiceHandler={invoiceHandler}/>;
    case 2:
      return <Buyer invoice={invoice} invoiceHandler={invoiceHandler}/>;
    case 3:
      return <Payment invoice={invoice} invoiceHandler={invoiceHandler}/>;
    case 4:
      return <Orders invoice={invoice} invoiceHandler={invoiceHandler}/>;
    case 5:
      return <Overview invoice={invoice} invoiceHandler={invoiceHandler} createInvoice={createInvoice}/>;
    default:
      return 'Unknown step';
  }
}

const Default = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <Paper>
    <main className={classes.layout}>
    <PaperMui className={classes.paper} elevation={0}>
      <Stepper alternativeLabel activeStep={activeStep}
        connector={<ColorlibConnector />}
        className={classes.stepper}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}
              onClick={handleStep(index)}
              className={classes.stepLabel}
            >
            <Typography className={classes.stepLabelText}>
              {label}
            </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Paper>
        <div>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => {props.clearInvoice(); handleReset()}}
          >
            Reset Form
          </Button>
          <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
            Back
          </Button>
          {activeStep === steps.length - 1 ? 
            null
          : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            className={classes.button}
          >
            Next
          </Button>
          )}
        </div>

        <div className={classes.instructions}>
          {getStepContent(activeStep, props.invoice, props.invoiceHandler, props.createInvoice)}
        </div>
      </Paper>

    </PaperMui>
    </main>
    </Paper>
  );
}

export default Default