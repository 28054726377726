import React from "react"

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Paper, { PaperClean } from '../../../components/Paper'

import Detail from './Detail'

const useStyles = makeStyles((theme) => ({

}));

const Default = ({data}) => {
  const classes = useStyles();

  return (
    <>
      <Paper>
        <Grid container>
        {data.map((obj, id) => {
          return <Grid key={id} item xs={12} md={6}>  
          <PaperClean>
            <Detail data={obj}/>
          </PaperClean>
          </Grid>
        })}
        </Grid>
      </Paper>
  </>
  )
}

export default Default
