import React from "react"
import { Router } from "@reach/router"
import { useAuth } from "../../context"

import {
  Invoicing,
  InvoicingAnonymous,
  InvoicingRoot
 } from '../../apps'


const Page = () => {
  const { user, profile } = useAuth()
  if (user===null) {
    return <InvoicingAnonymous />
  } else {
    if (profile===null) {
      return <Invoicing />
    }
    if (profile && profile.is_root) {
      return (
        <>
        <Router>
        <InvoicingRoot default="/invoicing"/> 
        </Router>
        </>
      )
    }
    if (profile && !profile.is_root) {
      return (
        <>
        <Router>
        <Invoicing default="/invoicing"/> 
        </Router>
        </>
      )
    }
  }
}

export default Page
