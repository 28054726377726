import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FieldTextControlled from '../../../../components/Form/FieldTextControlled'
import FieldDatePicker from '../../../../components/Form/FieldDatePicker'


export default function AddressForm({invoice, invoiceHandler}) {
  const setDisplay = (value) => {
    invoiceHandler({display: value})
  }
  const setIssueDate = (value) => {
    invoiceHandler({issue_date: value})
  }
  const setDueDate = (value) => {
    invoiceHandler({due_date: value})
  }
  return (
    <React.Fragment>
      <form>
      <Typography variant="h6" gutterBottom>
        Invoice
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FieldTextControlled
            label="Invoice Number"
            name="display" type="text"
            value={invoice.display} setValue={setDisplay}
            required={true}
          />
         </Grid>
        <Grid item xs={12} sm={6}>
          <FieldDatePicker
            label="Issue Date"
            value={invoice.issue_date} setValue={setIssueDate}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldDatePicker
            label="Due Date"
            value={invoice.due_date} setValue={setDueDate}
            required={true}
          />
        </Grid>
      </Grid>
      </form>
    </React.Fragment>
  );
}