import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FieldTextControlled from '../../../../components/Form/FieldTextControlled'

export default function AddressForm({invoice, invoiceHandler}) {
  const setBuyerCompanyLegal = (value) => {
    invoiceHandler({buyer_company_legal: value})
  }
  const setBuyerCompanyBrand = (value) => {
    invoiceHandler({buyer_company_brand: value})
  }
  const setBuyerFirstName = (value) => {
    invoiceHandler({buyer_first_name: value})
  }
  const setBuyerLastName = (value) => {
    invoiceHandler({buyer_last_name: value})
  }
  const setBuyerRegistration = (value) => {
    invoiceHandler({buyer_registration: value})
  }
  const setBuyerVat = (value) => {
    invoiceHandler({buyer_vat: value})
  }
  const setBuyerAddress = (value) => {
    invoiceHandler({buyer_address: value})
  }
  const setBuyerZip = (value) => {
    invoiceHandler({buyer_zip: value})
  }
  const setBuyerCity = (value) => {
    invoiceHandler({buyer_city: value})
  }
  const setBuyerCountry = (value) => {
    invoiceHandler({buyer_country: value})
  }
  const setBuyerPhone = (value) => {
    invoiceHandler({buyer_phone: value})
  }
  const setBuyerEmail = (value) => {
    invoiceHandler({buyer_email: value})
  }
  const setBuyerWeb = (value) => {
    invoiceHandler({buyer_web: value})
  }
  const setBuyerIg = (value) => {
    invoiceHandler({buyer_ig: value})
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Buyer
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="First Name"
            name="buyer_first_name" type="text"
            value={invoice.buyer_first_name} setValue={setBuyerFirstName}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="Last Name"
            name="buyer_last_name" type="text"
            value={invoice.buyer_last_name} setValue={setBuyerLastName}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="Company Legal Name"
            name="buyer_company_legal" type="text"
            value={invoice.buyer_company_legal} setValue={setBuyerCompanyLegal}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="Company Brand Name"
            name="buyer_company_brand" type="text"
            value={invoice.buyer_company_brand} setValue={setBuyerCompanyBrand}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="Company Reg Number"
            name="buyer_registration" type="text"
            value={invoice.buyer_registration} setValue={setBuyerRegistration}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="Company VAT Number"
            name="buyer_vat" type="text"
            value={invoice.buyer_vat} setValue={setBuyerVat}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <FieldTextControlled
            label="Address"
            name="buyer_address" type="text"
            value={invoice.buyer_address} setValue={setBuyerAddress}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FieldTextControlled
            label="Postal Code"
            name="buyer_zip" type="text"
            value={invoice.buyer_zip} setValue={setBuyerZip}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="City / State"
            name="buyer_city" type="text"
            value={invoice.buyer_city} setValue={setBuyerCity}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="Country"
            name="buyer_country" type="text"
            value={invoice.buyer_country} setValue={setBuyerCountry}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="Phone"
            name="buyer_phone" type="text"
            value={invoice.buyer_phone} setValue={setBuyerPhone}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="Email"
            name="buyer_email" type="text"
            value={invoice.buyer_email} setValue={setBuyerEmail}
            required={true}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}