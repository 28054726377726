import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import FieldTextControlled from '../../../../components/Form/FieldTextControlled'
import FieldMultiline from '../../../../components/Form/FieldMultiline'

export default function AddressForm({invoice, invoiceHandler}) {
  const setBicSwift = (value) => {
    invoiceHandler({bic_swift: value})
  }
  const setIban = (value) => {
    invoiceHandler({iban: value})
  }
  const setNotes = (value) => {
    invoiceHandler({notes: value})
  }
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Payment
      </Typography>
      <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="BIC / SWIFT"
            name="bic_swift" type="text"
            value={invoice.bic_swift} setValue={setBicSwift}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="IBAN"
            name="iban" type="text"
            value={invoice.iban} setValue={setIban}
            required={true}
          />
        </Grid>
        <Grid item xs={12}>
        <FieldMultiline
              label="Notes"
              name="notes" 
              value={invoice.notes} setValue={setNotes}
              required={true}
            />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}