import React from 'react';
import Button from '@material-ui/core/Button'
import Complete from '../Complete';

export default function AddressForm(props) {
  const {invoice, createInvoice, invoiceHandler} = props
  return (
    <>
      <Complete invoice={invoice} invoiceHandler={invoiceHandler}/>
      <Button
        style={{margin: '10px'}}
        onClick={() => createInvoice()} 
        variant='contained'
        color='primary'
      >
        Issue Invoice
      </Button>
    </>
  );
}

