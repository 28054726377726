import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function Default(props) {
  const {
    label, value, setValue, required
  } = props
  const handleDateChange = (date) => {
    setValue(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          style={{
            color: '#fff'
          }}
          color='secondary'
          margin="normal"
          id="date-picker-dialog"
          label={label}
          format="dd/MM/yyyy"
          value={value}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          required={required}
        />
    </MuiPickersUtilsProvider>
  );
}
