import React from "react"

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import Paper from '../../../components/Paper'

import List from '../components/List'

const useStyles = makeStyles((theme) => ({
  header: {
  },
  label: {
    fontSize: '10px'
  }
}));

const Default = ({data}) => {
  const classes = useStyles();

  return (
    <>
    {data && data.length > 0 ? (
      <List data={data} />
    ) : (
      <Paper>
      <Typography variant='body1' component='p'
        align='left'
      >
          You did not receive any invoices yet.
        </Typography>
      </Paper>
    )}
  </>
  )
}

export default Default
