import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    background: 'transparent',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paperOrderDescription: {
    background: 'transparent',
    padding: theme.spacing(1),
    textAlign: 'left',
    fontSize: '12px',
  },
  paperOrderValues: {
    background: 'transparent',
    textAlign: 'right',
    fontSize: '10px',
  },
  description: {

  }
}));

export default function FullWidthGrid({invoice, invoiceHandler}) {
  const classes = useStyles();

  const deleteHandler = (uid) => {
    let order = invoice.orders.filter(obj => obj.uid === uid)
    let newOrders = invoice.orders.filter(obj => obj.uid !== uid)

    let priceCurrent = parseFloat(invoice.price)
    let vatCurrent = parseFloat(invoice.vat)
    let totalCurrent = parseFloat(invoice.total)
    let priceOrder = parseFloat(order[0].price)
    let vatOrder = parseFloat(order[0].vat)
    let totalOrder = parseFloat(order[0].total)
    let priceSub = priceCurrent - priceOrder
    let vatSub = vatCurrent - vatOrder
    let totalSub = totalCurrent - totalOrder
    let price = priceSub.toFixed(2)
    let vat = vatSub.toFixed(2)
    let total = totalSub.toFixed(2)
    invoiceHandler({price, vat, total, orders: [...newOrders]})
  }

  return (
    <>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
          <Typography variant="h6">
            Orders
          </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paperOrderDescription}>
            <hr />
          </Paper>
        </Grid>
        {invoice.orders.map((obj) => (
          <React.Fragment key={obj.uid}>
        <Grid item xs={10}>
          <Paper className={classes.paperOrderDescription}>
            <b>{obj.title}</b>
            <br />
            {obj.description}
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paperOrderValues}>
          {obj.uid}
          <IconButton color="primary"
            onClick={() => deleteHandler(obj.uid)}
          >
            <DeleteOutlineIcon />
          </IconButton>
          </Paper>
        </Grid>
        <Grid item xs={2} sm={2}>
          <Paper className={classes.paperOrderValues}>[RATE]<br/>{obj.rate}</Paper>
        </Grid>
        <Grid item xs={2} sm={2}>
          <Paper className={classes.paperOrderValues}>[QTY]<br/>{obj.quantity}</Paper>
        </Grid>
        <Grid item xs={2} sm={2}>
          <Paper className={classes.paperOrderValues}>[PRICE]<br/>{obj.price}</Paper>
        </Grid>
        <Grid item xs={2} sm={2}>
          <Paper className={classes.paperOrderValues}>[VAT RATE]<br/>{obj.vat_rate}</Paper>
        </Grid>
        <Grid item xs={2} sm={2}>
          <Paper className={classes.paperOrderValues}>[VAT]<br/>{obj.vat}</Paper>
        </Grid>
        <Grid item xs={2} sm={2}>
          <Paper className={classes.paperOrderValues}>[TOTAL]<br/>{obj.total}</Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paperOrderDescription}>
            <hr />
          </Paper>
        </Grid>
          </React.Fragment>
        ))}

    </>
  );
}
