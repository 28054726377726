import React from "react"

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '10px'
  },
  value: {
    color: '#fff'
  }
}));

const Default = ({label, value, value1}) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant='body2' component='p'
        className={classes.label}
      >
        [{label}] 
      </Typography>
        <Typography variant='body2' component='p'
          className={classes.value}
        >
          {(value || value === 0) ? value + (value1 ? (' ' + value1) : '') : '-'}
        </Typography>
    </>
  )
}  

export default Default