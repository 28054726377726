import React, { useState, useEffect } from "react"

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { useAuth } from '../../context'

import Layout from '../../layouts/Website'
import Paper, { PaperClean } from '../../components/Paper'
import { HeadingBase } from '../../components/Heading'

import Guide from './views/Guide'
import Create from './views/Create'
import Manager from './views/Manager'
import Sales from './views/Sales'
import Purchases from './views/Purchases'

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '10px'
  },
  button: {
    marginLeft: '5px',
    marginRight: '5px'
  }
}));

const initialInvoice = {
  buyer_countrydisplay: '',
  seller_company_brand: '',
  seller_company_legal: '',
  seller_first_name: '',
  seller_last_name: '',
  seller_address: '',
  seller_zip: '',
  seller_city: '',
  seller_country: '',
  seller_registration: '',
  seller_vat: '',
  seller_phone: '',
  seller_email: '',
  seller_web: '',
  seller_ig: '',
  buyer_company_brand: '',
  buyer_company_legal: '',
  buyer_first_name: '',
  buyer_last_name: '',
  buyer_address: '',
  buyer_zip: '',
  buyer_city: '',
  buyer_country: '',
  buyer_registration: '',
  buyer_vat: '',
  buyer_phone: '',
  buyer_email: '',
  bic_swift: '',
  iban: '',
  issue_date: new Date(),  
  due_date: new Date(),
  notes: '',
  orders: [],
  price: 0,
  vat: 0,
  total: 0
}

const Default = () => {
  const classes = useStyles();
  const { user } = useAuth()
  const [ view, setView ] = useState('Issue Invoice')
  const [ error, setError] = useState('')
  const [ created, setCreated] = useState('')

  const [ invoicesSeller, setInvoicesSeller ] = useState([])
  const [ invoicesBuyer, setInvoicesBuyer ] = useState([])
  const [ meta, setMeta ] = useState(null)

  const [ invoice, setInvoice ] = useState(initialInvoice)

  const invoiceHandler = (data) => {
    setInvoice({...invoice, ...data})
  }
  const clearInvoice = () => {
    setInvoice(initialInvoice)
  }
  const viewHandler = (view) => {
    setView(view)
  }

  const loadInvoices = () => {
    user.getIdToken(/* forceRefresh */ true).then((idToken) => {
      let data = {
        token: idToken,
      }
      try {
        fetch(`${process.env.GATSBY_API_URL}/skllchain/invoices`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(data => {
          if (data.status === 0) {
            setMeta(data.payload.meta)
            setInvoicesSeller(data.payload.data.seller)
            setInvoicesBuyer(data.payload.data.buyer)
          } else {
            setError('Something went wrong.')
          }
        })
        .catch(() => {
          setError('Something went wrong.')
        });
      } catch {
        setError('Something went wrong.');
      }
    }).catch(() => {
      setError('Something went wrong.')
    });
  }

  const createInvoice = () => {
    user.getIdToken(/* forceRefresh */ true).then((idToken) => {
      let data = {
        token: idToken,
        invoice: {
          display: invoice.display.toString(),
          seller_company_brand: invoice.seller_company_brand.toString(),
          seller_company_legal: invoice.seller_company_legal.toString(),
          seller_first_name: invoice.seller_first_name.toString(),
          seller_last_name: invoice.seller_last_name.toString(),
          seller_address: invoice.seller_address.toString(),
          seller_zip: invoice.seller_zip.toString(),
          seller_city: invoice.seller_city.toString(),
          seller_country: invoice.seller_country.toString(),
          seller_registration: invoice.seller_registration.toString(),
          seller_vat: invoice.seller_vat.toString(),
          seller_phone: invoice.seller_phone.toString(),
          seller_email: invoice.seller_email.toString(),
          seller_web: invoice.seller_web.toString(),
          seller_ig: invoice.seller_ig.toString(),
          buyer_company_brand: invoice.buyer_company_brand.toString(),
          buyer_company_legal: invoice.buyer_company_legal.toString(),
          buyer_first_name: invoice.buyer_first_name.toString(),
          buyer_last_name: invoice.buyer_last_name.toString(),
          buyer_address: invoice.buyer_address.toString(),
          buyer_zip: invoice.buyer_zip.toString(),
          buyer_city: invoice.buyer_city.toString(),
          buyer_country: invoice.buyer_country.toString(),
          buyer_registration: invoice.buyer_registration.toString(),
          buyer_vat: invoice.buyer_vat.toString(),
          buyer_phone: invoice.buyer_phone.toString(),
          buyer_email: invoice.buyer_email.toString(),
          bic_swift: invoice.bic_swift.toString(),
          iban: invoice.iban.toString(),
          issue_date: invoice.issue_date.toJSON().slice(0,10), 
          due_date: invoice.due_date.toJSON().slice(0,10),
          notes: invoice.notes.toString(),
          price: invoice.price.toString(),
          vat: invoice.vat.toString(),
          total: invoice.total.toString(),
          orders: invoice.orders,
        }
      }
      console.log(data)
      try {
        fetch(`${process.env.GATSBY_API_URL}/skllchain/invoices`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(data => {
          if (data.status === 0) {
            setCreated(`Invoice < ${invoice.display} > successfully created. `)
            clearInvoice()
          } else {
            setError('Something went wrong.')
          }
        })
        .catch(() => {
          setError('Something went wrong.')
        });
      } catch {
        setError('Something went wrong.');
      }
    }).catch(() => {
      setError('Something went wrong.')
    });
  }

  useEffect(() => {
    user && loadInvoices()
    const interval = setInterval(() => {
      loadInvoices()
    }, 10000);
    return () => clearInterval(interval);
  }, []);


  return (
    <Layout>
        <Grid item xs={12}>
          <Paper>
            <HeadingBase title={view} />
          </Paper>
          <PaperClean>
            <Typography variant='h6' component='p'>
             <Button onClick={() => viewHandler('Documentation')}
                variant={view==='Documentation' ? 'outlined' : 'text'}
                color='primary'
                className={classes.button}
              >
                Features
              </Button>
              <Button onClick={() => viewHandler('Issue Invoice')}
                variant={view==='Issue Invoice' ? 'outlined' : 'text'}
                color='primary'
                className={classes.button}
              >
                Create
              </Button>
              <Button onClick={() => viewHandler('Invoice Manager')}
                variant={view==='Invoice Manager' ? 'outlined' : 'text'}
                color='primary'
                className={classes.button}
              >
                Manager
              </Button>
              <Button onClick={() => viewHandler('Sales')}
                variant={view==='Sales' ? 'outlined' : 'text'}
                color='primary'
                className={classes.button}
              >
                Sales
              </Button>
              <Button onClick={() => viewHandler('Purchases')}
                variant={view==='Purchases' ? 'outlined' : 'text'}
                color='primary'
                className={classes.button}
              >
                Purchases
              </Button>
            </Typography>
          </PaperClean>

        </Grid>
        {view === 'Documentation' && (
        <Grid item xs={12}>
          <Guide
          />
        </Grid>
        )}
        {view === 'Issue Invoice' && (
        <Grid item xs={12}>
          <Create 
            invoice={invoice}
            invoiceHandler={invoiceHandler}
            clearInvoice={clearInvoice}
            createInvoice={createInvoice}
          />
        </Grid>
        )}
        {view === 'Invoice Manager' && (
        <Grid item xs={12}>
          <Manager
            meta={meta}
          />
        </Grid>
        )}
        {view === 'Sales' && (
        <Grid item xs={12}>
          <Sales
            data={invoicesSeller}
          />
        </Grid>
        )}
        {view === 'Purchases' && (
        <Grid item xs={12}>
          <Purchases
            data={invoicesBuyer}
          />
        </Grid>
        )}
    </Layout>
  )
}

export default Default