import React, { useState, useEffect } from "react"

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { useAuth } from '../../context'

import Layout from '../../layouts/Website'
import Paper from '../../components/Paper'
import { HeadingBase } from '../../components/Heading'

import Purchases from './views/Purchases'

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '10px'
  },
  button: {
    marginLeft: '5px',
    marginRight: '5px'
  }
}));

const Default = () => {
  const classes = useStyles();
  const { user } = useAuth()
  const [ invoicesBuyer, setInvoicesBuyer ] = useState([])

  const loadInvoices = () => {
    user.getIdToken(/* forceRefresh */ true).then((idToken) => {
      let data = {
        token: idToken,
      }
      try {
        fetch(`${process.env.GATSBY_API_URL}/skllchain/invoices`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(data => {
          if (data.status === 0) {
            setInvoicesBuyer(data.payload.data.buyer)
          } else {
            console.log('Something went wrong.')
          }
        })
        .catch(() => {
          console.log('Something went wrong.')
        });
      } catch {
        console.log('Something went wrong.');
      }
    }).catch(() => {
      console.log('Something went wrong.')
    });
  }

  useEffect(() => {
    user && loadInvoices()
    const interval = setInterval(() => {
      loadInvoices()
    }, 10000);
    return () => clearInterval(interval);
  }, []);


  return (
    <Layout>
        <Grid item xs={12}>
          <Paper>
            <HeadingBase title='Invoices' />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Purchases
            data={invoicesBuyer}
          />
        </Grid>
    </Layout>
  )
}

export default Default