import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Orders from '../Orders'
import FieldTextControlled from '../../../../components/Form/FieldTextControlled'
import Attribute from '../Attribute'

const useStyles = makeStyles((theme) => ({
  orders: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'right',
    background: 'transparent',
    color: theme.palette.text.secondary,
  },
}));


const OrderForm = (props) => {
  const classes = useStyles();
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [rate, setRate] = useState('')
  const [quantity, setQuantity] = useState('')
  const [price, setPrice] = useState('')
  const [vat_rate, setVatRate] = useState('')
  const [vat, setVat] = useState('')
  const [total, setTotal] = useState('')

  const clearForm = () => {
    setTitle(''); setDescription(''); setRate('');
    setQuantity(''); setPrice(''); setVatRate(''); setVat('');
    setTotal('')
  }

  const handleRate = (value) => {
    setRate(value)
    let priceNew = parseFloat(quantity) * parseFloat(value)
    let totalNew = parseFloat(priceNew) * ( 1 + parseFloat(vat_rate) * 0.01)
    let vatNew = parseFloat(priceNew) * ( parseFloat(vat_rate) * 0.01)
    setPrice(priceNew.toFixed(2).toString())
    setTotal(totalNew.toFixed(2).toString())
    setVat(vatNew.toFixed(2).toString())
  }
  const handleQuantity = (value) => {
    setQuantity(value)
    let priceNew = parseFloat(rate) * parseFloat(value)
    let totalNew = parseFloat(priceNew) * ( 1 + parseFloat(vat_rate) * 0.01)
    let vatNew = parseFloat(priceNew) * ( parseFloat(vat_rate) * 0.01)
    setPrice(priceNew.toFixed(2).toString())
    setTotal(totalNew.toFixed(2).toString())
    setVat(vatNew.toFixed(2).toString())
  }
  const handleVatRate = (value) => {
    let totalNew = parseFloat(price) * ( 1 + parseFloat(value) * 0.01)
    let vatNew = parseFloat(price) * ( parseFloat(value) * 0.01)
    setVatRate(value.toString())
    setTotal(totalNew.toFixed(2).toString())
    setVat(vatNew.toFixed(2).toString())
  }
  const handleOrder = (event) => {
    event.preventDefault()
    let len = props.invoice.orders.length
    let newUid = len === 0 ? 1 : props.invoice.orders[len-1].uid + 1
    const order = {
      uid: newUid,
      title,
      description,
      rate,
      quantity,
      price,
      vat_rate,
      vat,
      total,
    }
    props.submitOrder(order)
    clearForm()
  }

  return (
    <React.Fragment>
      <form onSubmit={handleOrder}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <FieldTextControlled
            label="Order Title"
            name='title' type="text"
            value={title} setValue={setTitle}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <FieldTextControlled
            label="Order Description"
            name='description' type="text"
            value={description} setValue={setDescription}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FieldTextControlled
            label="Rate"
            name='rate' type="text"
            value={rate} setValue={handleRate}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FieldTextControlled
            label="Quantity"
            name='quantity' type="text"
            value={quantity} setValue={handleQuantity}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FieldTextControlled
            label="VAT Rate"
            name='vat_rate' type="text"
            value={vat_rate} setValue={handleVatRate}
            required={true}
          />
        </Grid>
        </Grid>
        <div className={classes.root}>
        <Grid container justify='flex-end'
          spacing={3}
        >
        <Grid item xs={12} sm={4}>
        <Paper className={classes.paper}>
        <Attribute
            label='NET PRICE'
            value={price}
          />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4} >
        <Paper className={classes.paper}>
        <Attribute
            label='VAT'
            value={vat}
          /> 
        </Paper>
        </Grid>
        <Grid item xs={12} sm={4} >
        <Paper className={classes.paper}>
         <Attribute
            label='TOTAL'
            value={total}
          />
          </Paper>
        </Grid>
      </Grid>
      </div>
      <Button
        variant="contained"
        color="primary"
        type='submit'
      >
        Submit Order
      </Button>
      </form>
    </React.Fragment>
  );
}

export default function Default({invoice, invoiceHandler}) {
  const classes = useStyles();

  const submitOrder = (order) => {
    let priceCurrent = parseFloat(invoice.price)
    let vatCurrent = parseFloat(invoice.vat)
    let totalCurrent = parseFloat(invoice.total)
    let priceOrder = parseFloat(order.price)
    let vatOrder = parseFloat(order.vat)
    let totalOrder = parseFloat(order.total)
    let priceSum = priceCurrent + priceOrder
    let vatSum = vatCurrent + vatOrder
    let totalSum = totalCurrent + totalOrder
    let price = priceSum.toFixed(2).toString()
    let vat = vatSum.toFixed(2).toString()
    let total = totalSum.toFixed(2).toString()
    invoiceHandler({price, vat, total, orders: [...invoice.orders, order]})
  }
  
  return (
    <React.Fragment>
      <div className={classes.orders}>
      {invoice.orders.length > 0 ? (
        <div className={classes.root}>
        <Grid container spacing={1}>
        <Orders invoice={invoice} invoiceHandler={invoiceHandler} />
        </Grid>
        </div>
        )
        : (
        <Typography variant="subtitle1" gutterBottom>
        Order list is empty.
        </Typography>
        )
      }
      </div>

      <Typography variant="h6" gutterBottom>
        Create Order
      </Typography>

      <OrderForm submitOrder={submitOrder} invoice={invoice}/>

    </React.Fragment>
  );
}