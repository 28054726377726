import React from "react"

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Paper, { PaperClean } from '../../../components/Paper'

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '10px'
  }
}));

const Default = ({meta}) => {
  const classes = useStyles();

  return (
    <Paper>
      <Grid container>

      <Grid item xs={12} md={6}>
        <PaperClean>
        <Typography variant='h5' component='h2'
          paragraph
        >
          Overview
        </Typography>
        <Grid container>
          <Grid item xs={6}>
            <PaperClean>
            <Typography variant='body2' component='p'
              align='left'
              paragraph
            >
              SALES
            </Typography>
            <Typography variant='body2' component='p'
              align='left'
              className={classes.label}
            >
              [TOTAL]
            </Typography>
            <Typography variant='body2' component='p'
              align='left' paragraph
            >
              {meta ? meta.sellerCount : '~'}
            </Typography>
            </PaperClean>
          </Grid>
          <Grid item md={6}>
            <PaperClean>
            <Typography variant='body2' component='p'
              align='left'
              paragraph
            >
              PURCHASES
            </Typography>
            <Typography variant='body2' component='p'
              align='left'
              className={classes.label}
            >
              [TOTAL]
            </Typography>
            <Typography variant='body2' component='p'
              align='left' 
            >
              {meta ? meta.buyerCount : '~'}
            </Typography>
            </PaperClean>
          </Grid>
        </Grid>
          </PaperClean>
        </Grid>

        <Grid item xs={12} sm={6}>
          <PaperClean>
            <Typography variant='h5' component='h2'>
            </Typography>
            
          </PaperClean>
        </Grid>

      </Grid>
    </Paper>

  )
}

export default Default
