import React from "react"

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Paper, { PaperClean } from '../../../components/Paper'

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '10px'
  }
}));

const Default = ({meta}) => {
  const classes = useStyles();

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12} sm={6}>
         <PaperClean>
         <Typography variant='h5' component='h2'>
          Anonymous
        </Typography>
        <ul>
          <li>
            <Typography variant='subtitle1' component='h2'
              align='left' paragraph
            >
              create invoices
            </Typography>
          </li>
          <li>
            <Typography variant='subtitle1' component='h2'
              align='left' paragraph
            >
              download PDF versions
            </Typography>
          </li>
        </ul>
          </PaperClean>
        </Grid>

        <Grid item xs={12} sm={6}>
         <PaperClean>
         <Typography variant='h5' component='h2'>
         Registered
        </Typography>
        <ul>
          <li>
            <Typography variant='subtitle1' component='h2'
              align='left' paragraph
            >
              create invoices
            </Typography>
          </li>
          <li>
            <Typography variant='subtitle1' component='h2'
              align='left' paragraph
            >
              save invoices to database
            </Typography>
          </li>
          <li>
            <Typography variant='subtitle1' component='h2'
              align='left' paragraph
            >
              download PDF versions
            </Typography>
          </li>
          <li>
            <Typography variant='subtitle1' component='h2'
              align='left' paragraph
            >
              issue/receive invoices to/from other users
            </Typography>
          </li>
          <li>
            <Typography variant='subtitle1' component='h2'
              align='left' paragraph
            >
              share invoices with team members
            </Typography>
          </li>
        </ul>

          </PaperClean>
        </Grid>

      </Grid>
    </Paper>

  )
}

export default Default
