import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FieldTextControlled from '../../../../components/Form/FieldTextControlled'

export default function AddressForm({invoice, invoiceHandler}) {
  const setSellerCompanyLegal = (value) => {
    invoiceHandler({seller_company_legal: value})
  }
  const setSellerCompanyBrand = (value) => {
    invoiceHandler({seller_company_brand: value})
  }
  const setSellerFirstName = (value) => {
    invoiceHandler({seller_first_name: value})
  }
  const setSellerLastName = (value) => {
    invoiceHandler({seller_last_name: value})
  }
  const setSellerRegistration = (value) => {
    invoiceHandler({seller_registration: value})
  }
  const setSellerVat = (value) => {
    invoiceHandler({seller_vat: value})
  }
  const setSellerAddress = (value) => {
    invoiceHandler({seller_address: value})
  }
  const setSellerZip = (value) => {
    invoiceHandler({seller_zip: value})
  }
  const setSellerCity = (value) => {
    invoiceHandler({seller_city: value})
  }
  const setSellerCountry = (value) => {
    invoiceHandler({seller_country: value})
  }
  const setSellerPhone = (value) => {
    invoiceHandler({seller_phone: value})
  }
  const setSellerEmail = (value) => {
    invoiceHandler({seller_email: value})
  }
  const setSellerWeb = (value) => {
    invoiceHandler({seller_web: value})
  }
  const setSellerIg = (value) => {
    invoiceHandler({seller_ig: value})
  }
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Seller
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="First Name"
            name="seller_first_name" type="text"
            value={invoice.seller_first_name} setValue={setSellerFirstName}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="Last Name"
            name="seller_last_name" type="text"
            value={invoice.seller_last_name} setValue={setSellerLastName}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="Company Legal Name"
            name="seller_company_legal" type="text"
            value={invoice.seller_company_legal} setValue={setSellerCompanyLegal}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="Company Brand Name"
            name="seller_company_brand" type="text"
            value={invoice.seller_company_brand} setValue={setSellerCompanyBrand}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="Company Reg Number"
            name="seller_registration" type="text"
            value={invoice.seller_registration} setValue={setSellerRegistration}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="Company VAT Number"
            name="seller_vat" type="text"
            value={invoice.seller_vat} setValue={setSellerVat}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <FieldTextControlled
            label="Address"
            name="seller_address" type="text"
            value={invoice.seller_address} setValue={setSellerAddress}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FieldTextControlled
            label="Postal Code"
            name="seller_zip" type="text"
            value={invoice.seller_zip} setValue={setSellerZip}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="City / State"
            name="seller_city" type="text"
            value={invoice.seller_city} setValue={setSellerCity}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="Country"
            name="seller_country" type="text"
            value={invoice.seller_country} setValue={setSellerCountry}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="Phone"
            name="seller_phone" type="text"
            value={invoice.seller_phone} setValue={setSellerPhone}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="Email"
            name="seller_email" type="text"
            value={invoice.seller_email} setValue={setSellerEmail}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="Website"
            name="seller_web" type="text"
            value={invoice.seller_web} setValue={setSellerWeb}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldTextControlled
            label="Instagram"
            name="seller_ig" type="text"
            value={invoice.seller_ig} setValue={setSellerIg}
            required={true}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}