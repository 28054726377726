import React from "react"

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  pdf: {
    textAlign: 'left',
    fontSize: '20px'
  },
  label: {
    fontSize: '10px'
  }
}));

const Default = ({data}) => {
  const {
    display,
    payment_status,
    seller_company_legal,
    buyer_company_legal,
    buyer_first_name,
    buyer_last_name,
    issue_date,
    due_date,
  } = data
  const classes = useStyles();
  return (
    <>
        <Typography variant='h6' component='p'
            align='left' 
          >
            # {display}
          </Typography>

          <Typography variant='body2' component='p'
            align='left'
            className={classes.label}
          >
            [STATUS] 
          </Typography>
          <Typography variant='body2' component='p'
            align='left' 
          >
            {payment_status}
          </Typography>
          <Typography variant='body2' component='p'
            align='left'
            className={classes.label}
          >
            [FROM] 
          </Typography>
          <Typography variant='body2' component='p'
            align='left' 
          >
            {seller_company_legal}
          </Typography>
          <Typography variant='body2' component='p'
            align='left'
            className={classes.label}
          >
            [TO] 
          </Typography>
          <Typography variant='body2' component='p'
            align='left' 
          >
            {buyer_company_legal ? buyer_company_legal : buyer_first_name + ' ' + buyer_last_name}
          </Typography>
          <Typography variant='body2' component='p'
            align='left'
            className={classes.label}
          >
            [ISSUED] 
          </Typography>
          <Typography variant='body2' component='p'
            align='left' 
          >
            {issue_date}
          </Typography>
          <Typography variant='body2' component='p'
            align='left'
            className={classes.label}
          >
            [DUE] 
          </Typography>
          <Typography variant='body2' component='p'
            align='left' 
          >
            {due_date}
          </Typography>
    </>
  )
}  

export default Default